app.controller('eventSectionCtrl', ['$scope', function($scope) {
    "use strict";



}]);




