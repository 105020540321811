angular.module("app").controller("ClubModalCtrl", ["$scope", "$http", function( $scope, $http ){
    "use strict";

    console.log("club-modalCtrl");
    var loc = document.getElementById("global_loc").innerText;
    var plswait = document.getElementById("global_plswait").innerText;

    $scope.submitClubForm = function() {
        if ($scope.RegisterClubForm.$valid) {
            angular.element(document.querySelector(".ModalBox .formWr .message_group")).html('<span class="wait"><i class="fa fa-exclamation-circle" aria-hidden="true"></i>'+plswait+'</span>');
            var redirect = document.getElementById("locale").value;
            
            $http({
                method: 'POST',
                url: '/'+loc+'/clubs/create_application',
                data: "country="+this.user.country+
                      "&city="+this.user.city+
                      "&clubName="+this.user.clubName+
                      "&contact="+this.user.contact+
                      "&email="+this.user.email+
                      "&phone="+(this.user.phone ? this.user.phone: ''),
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}  //set the headers so angular passing info as form data (not request payload)
            }).then(function (result) {
                var parser = new DOMParser();
                var message_group = parser.parseFromString(result.data, 'text/html').querySelectorAll('.hidden_error');
                var error = angular.element(message_group).html();

                if(error && error.indexOf('span') > -1){
                    angular.element(document.querySelector(".ModalBox .message_group")).html(error);
                } else {
                    redirect = redirect + '?application=success';
                    document.location.replace(redirect);
                }
            }, function(result) {
                angular.element(document.querySelector(".ModalBox .message_group")).html('');
                angular.element(document.querySelector(".ModalBox .message_group")).append('<span style="margin-bottom: 30px;" class="red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> '+result.data+'</span>');
                // not code 200
            });
        }
    };
}]);
