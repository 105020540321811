// ===[ APP.JS ]==============================================
'use strict';

var app = angular.module("app", [
    "ngAnimate",
    "ui.bootstrap",
    "ngStorage",
    "ngTouch",
    "ngDialog",
    // "ngSanitize",
    // "ngMaterial",
    // "ngMessages",
    // "ngAria"
]);

