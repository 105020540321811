app.controller('carusel_sectionCtrl', ['$scope', function($scope) {
    "use strict";
    //
    $scope.myInterval = 5000;
    $scope.noWrapSlides = false;
    $scope.active = 0;

}]);




