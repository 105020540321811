app.controller("SingleCtrl", ["$scope", "ngDialog", function( $scope, ngDialog ){
  "use strict";

    console.log('SingleCtrl');

    $scope.OpenModal = function (modal) {
        ngDialog.open({
            template: modal,
            className: 'ngdialog-theme-plain'
        });
    };
}]);
