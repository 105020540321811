app.controller("ClubsCtrl", ["$scope", "ngDialog", function( $scope, ngDialog ){
    "use strict";

    console.log('ClubsCtrl');

    $scope.OpenModal = function (modal) {
        ngDialog.open({
            template: modal,
            className: 'ngdialog-theme-plain'
        });
    };
}]);
